import style from './ServicesPay.module.scss';

// React
import { useEffect, useState, useRef } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { selectPackages } from '../../redux/packages/selectors';
import { PromoCodeFormData, Status } from '../../redux/promocode/types';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Telegram
import { useWebApp, MainButton, WebAppUser, useShowPopup } from '@vkruglikov/react-telegram-web-app';

// Route
import { useParams } from 'react-router-dom';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { selectPayments } from '../../redux/payments/selectors';
import { setPaymentStatusIsNull, setPaymentUrlIsNull } from '../../redux/payments/slice';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { CreateUrlPaymentFormData, CheckPaymentFormData } from '../../redux/payments/types';
import { requestMyServices } from '../../redux/my-services/asyncActions';
import { TelegramBackBtn } from '../../components/utils/buttons/Buttons';
import { requestCreatePaymentUrl, requestCheckPayment } from '../../redux/payments/asyncActions';
import { QuestionCircleOutlined } from '@ant-design/icons';


const checkEmailRegex = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
        return true;
    }
    else {
        return false;
    }
}


const ServicesPay = () => {
    let { alias, type } = useParams();
    const [promocode, setPromocode] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    // Redux
    const dispatch = useAppDispatch();
    const { activePackage } = useSelector(selectPackages);
    const intervalIdsRef = useRef<NodeJS.Timeout[]>([]);

    useEffect(() => {
        if (alias && type) {
            dispatch(setPackageByAlias({
                alias: alias,
                type: type,
            }))
        }
    }, [dispatch, alias, type])

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate Promocode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }
 
        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Payment Handler
    const WebApp = useWebApp();
    const showPopup = useShowPopup();
    const { payment_url, statusPayment } = useSelector(selectPayments);

    const user: WebAppUser = WebApp.initDataUnsafe.user;

    const payment_handler = () => {
        if (activePackage) {
            if (email.length <= 0) {
                messageApi.error('Пожалуйста введите Email', 2);
                return;
            }
            if (!checkEmailRegex(email)) {
                messageApi.error('Пожалуйста введите Валидный Email', 2);
                return;
            }
            
            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: promo_package ? promo_package.price : activePackage.price,
                telegram_id: user.id,
                description: activePackage.description,
                verbose_name: activePackage.verbose_name,
                email: email,
            }
            dispatch(requestCreatePaymentUrl(formData));
        }
    }

    const [isOpenInvoice, setOpenInvoice] = useState<boolean>(false);

    useEffect(() => {
        if (payment_url) {
            // clearAllIntervals();
            WebApp.openInvoice(payment_url);
            setOpenInvoice(true);

            WebApp.onEvent('invoiceClosed', (object: any) => {
                if (object.status === 'cancelled') {
                    messageApi.info('Вы отменили оплату', 2);
                    // clearAllIntervals();
                    dispatch(setPaymentUrlIsNull());
                    dispatch(setPaymentStatusIsNull());
                }
            });
        }
        return () => {
            clearAllIntervals();

            // dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(setPaymentStatusIsNull());
            dispatch(requestMyServices());
        }
    }, [payment_url, WebApp, dispatch])

    useEffect(() => {
        if (isOpenInvoice) {
            // Check Payment
            const formData: CheckPaymentFormData = {
                telegram_id: user.id,
            }

            if (intervalIdsRef.current.length === 0) {
                const intervalId = setInterval(() => {
                    dispatch(requestCheckPayment(formData));
                }, 5000);

                // Сохраняем идентификатор интервала в массиве
                intervalIdsRef.current.push(intervalId);
            }
        }
    }, [isOpenInvoice, dispatch])

    useEffect(() => {
        if (statusPayment) {
            clearAllIntervals();
            messageApi.success('Вы успешно оплатили услугу', 2);
        }
    }, [statusPayment])

    const clearAllIntervals = () => {
        intervalIdsRef.current.forEach(intervalId => clearInterval(intervalId));
        intervalIdsRef.current = [];  // Очищаем массив после удаления всех интервалов
    };
    
    return (
        <div className={style.service_pay}>
            {contextHolder}
            <TelegramBackBtn url='home/service-info' />
            {!activePackage
                ? (
                    <Spinner />
                )
                : (
                    <>
                        <div className={style.title}>
                            <h1>Покупка Услуги</h1>
                        </div>
                        <div className={style.service_pay__name}>
                            <h2>Услуга {activePackage.verbose_name}</h2>
                        </div>
                        <div className={style.service_pay__price}>
                            <p>Стоимость</p>
                            <p>{promo_package ? promo_package.price : activePackage?.price} ₽</p>
                        </div>
                        <div className={style.service_pay__promocode}>
                            <p>Есть промокод?</p>
                            <form>
                                <div className={style.service_pay__promocode__block}>
                                    <input
                                        type="text"
                                        value={promocode}
                                        onChange={(e) => setPromocode(e.target.value)} />
                                    <button onClick={handlerActivatePromocode}>Применить</button>
                                </div>
                            </form>
                            <div className={style.email_title}>
                                <p>Ваш Email</p>
                                <QuestionCircleOutlined
                                    onClick={() => messageApi.info('Ваш Email нужен, для того, чтобы отправить чек вам на почту', 2)}
                                />
                            </div>
                            <form>
                                <div className={style.service_pay__email_block}>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </form>
                        </div>
                    </>
                )}
                <MainButton text='Перейти к оплате' color='#F24259' onClick={payment_handler} />
        </div>
    )
}

export default ServicesPay;